
import {DetailParams} from "../../abase/DetailParams";
import InfoItem from "../base/info-item";
import InfoBlockArea from "../base/info-block-area";
import InfoGroup from "../base/info-group";
import CaseImagePreview from "../../../base/case-image-preview";
import {is_mobile} from "../../../a-hybrid/utils";
import {useInfoImage} from "../base/useInfoImage";
import InfoNoData from "../base/info-no-data";
import InfoPeoplePhone from "../base/info-people-phone";

export default {
    name: "info-transferInfos",
    components: {InfoNoData, CaseImagePreview, InfoGroup, InfoBlockArea, InfoItem,InfoPeoplePhone},
    props: {
        detail_info: {
            type: Object,
            default() {
                return {};
            }
        },
        detail_params: DetailParams,
    },
    computed: {},
    setup(props) {
        let {image_up, image_down, text_show} = useInfoImage(props.detail_params)
        return {
            image_up,
            image_down,
            text_show,
        }
    },
    data() {
        return {
            is_mobile: is_mobile(),
        }
    },
    methods: {

    }
}
