
import {DetailParams} from "../../abase/DetailParams";
import InfoItem from "../base/info-item";
import InfoBlockArea from "../base/info-block-area";
import InfoGroup from "../base/info-group";
import CaseImagePreview from "../../../base/case-image-preview";
import {is_mobile} from "../../../a-hybrid/utils";
import {RequisitionTypeEnum} from "@enum/CaseEnum";
import {useInfoImage} from "../base/useInfoImage";
import InfoNoData from "../base/info-no-data";

export default {
  name: "info-difficult",
  components: {InfoNoData, CaseImagePreview, InfoGroup,  InfoBlockArea, InfoItem,},
  props: {
    detail_info: {
      type: Object,
      default() {
        return {};
      }
    },
    detail_params: DetailParams,
  },
  computed: {},
  setup(props){
    let {image_up,image_down,text_show} = useInfoImage(props.detail_params)
    return {
      image_up,
      image_down,
      text_show,
    }
  },
  data(){
    return {
      is_mobile:is_mobile(),
      RequisitionTypeEnum,
    }
  },
  methods: {
    content_color(difficultConfirm) {
      //1.待审核,2.审核通过,3.审核不通过
      return difficultConfirm.confirmState == 2 ? '#52c41a' : 'red';
    },
  }
}
