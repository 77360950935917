
import {DetailParams} from "../../abase/DetailParams";
import InfoItem from "../base/info-item";
import InfoBlockArea from "../base/info-block-area";
import HybridMapShow from "../../../a-hybrid/hybrid-map-show";

export default {
  name: "info-basic",
  components: {HybridMapShow, InfoBlockArea, InfoItem, },
  props:{
    detail_info:{
      type:Object,
      default(){
        return {};
      }
    },
    detail_params: DetailParams,
  },
  computed:{
    area_name(){
      return this.detail_info.streetName+'-'+this.detail_info.communityName+'-'+this.detail_info.cellName;
    }
  },
  methods:{
    showMap(){
      this.$refs.map.open();
    }
  }
}
