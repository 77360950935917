import {ref,reactive} from "@vue/composition-api";
import {is_mobile} from "../../../a-hybrid/utils";

export function useInfoImage(detail_params) {
    let data = reactive({
        image_up:{
            show:false,
            theme:'mini',
        },
        image_down:{
            show:true,
            theme:'mini',
        },
        text_show:true,
    });
    const is_mobile_val = is_mobile();
    //info_show_type  [{id:1,name:'信息图片'},{id:2,name:'信息'},{id:3,name:'图片'}]

    console.log(detail_params.layout);
    if(detail_params.layout.info_show_type == 3){
        data.text_show = false;
    }
    if(detail_params.layout.info_show_type == 2){
        data.image_down.show = false;
    }else{
        if(!is_mobile_val){
            if( detail_params.layout.span==2 ){
                data.image_up.show = true;
                data.image_down.show = false;
                data.image_up.theme = 'mini_slide';
            }else{
                if(detail_params.layout.info_show_type == 3){
                    data.image_down.theme = 'big_slide';
                }
            }
        }

    }

    return {
        image_up:data.image_up,
        image_down:data.image_down,
        text_show:data.text_show,
    }
}