
import {is_mobile} from "../../a-hybrid/utils";
import {DetailParams} from "../abase/DetailParams";
import moment from "moment";
import StageInfoAction from "./stage-info-action";
import HybridEmpty from "../../a-hybrid/lib/hybrid-empty";
import HybridIcon from "../../a-hybrid/lib/hybrid-icon";
import {processConfServerProcedureQuery} from "@api";

export default {
  name: "stage-infos",
  components: {HybridIcon, HybridEmpty, StageInfoAction},
  props:{
    detail_info:{
      type:Object,
      default(){
        return {};
      }
    },
    detail_params: DetailParams,
  },

  data(){
    return {
      is_mobile:is_mobile(),
    }
  },
  created() {
  },
  methods:{


  },
  beforeDestroy() {
  }
}
