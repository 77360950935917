
import {DetailParams} from "../../abase/DetailParams";
import InfoItem from "../base/info-item";
import InfoBlockArea from "../base/info-block-area";
import InfoGroup from "../base/info-group";
import InfoPeoplePhone from "../base/info-people-phone";

import CaseImagePreview from "../../../base/case-image-preview";
import {useInfoImage} from "../base/useInfoImage";
import HybridMapShow from "../../../a-hybrid/hybrid-map-show";

import InfoNoData from "../base/info-no-data";
import {is_mobile} from "../../../a-hybrid/utils";

export default {
  name: "info-dispose",
  components: {InfoNoData, CaseImagePreview, InfoGroup,  InfoBlockArea, InfoItem,HybridMapShow,InfoPeoplePhone},
  props:{
    detail_info:{
      type:Object,
      default(){
        return {};
      }
    },
    detail_params: DetailParams,
  },
  setup(props){
    let {image_up,image_down,text_show} = useInfoImage(props.detail_params)
    return {
      image_up,
      image_down,
      text_show,
    }
  },
  computed:{

  },
  methods:{
    showMap(disposeInfo){
      if(is_mobile()){
        this.$webSdk.jumpThirdPartyMapNavigation({
          params: {
            target_location: {
              lat: disposeInfo.lat,
              lng: disposeInfo.lng,
              address:disposeInfo.address
            },
            des:  disposeInfo.address
          }
        })
      }else{
        this.$refs.map.open();
      }
    }
  }
}
