
  import {is_mobile} from "../../../a-hybrid/utils";
  import InfoItem from "./info-item";

  export default {
    name: "info-people-phone",
    props: {
      label: String,
      content: [String, Number, Array],
      phone: String,
    },
    components: {InfoItem},
    watch: {},
    data() {
      return {
        is_mobile: is_mobile(),
      };
    },
    methods: {
      call(){
        if( this.phone ){
          this.$webSdk.callPhone({phone:this.phone})
        }else{
          this.$message.error('无该人员联系电话');
        }

      },
    }
  };
