
import DetailForm from './form/detail-form.vue'
import { event_server_banli_listConfig, event_server_banli_list, event_server_banli_detail } from '@/api/projects/zhjg'
const columns = [
    {
        title: '事件编号',
        dataIndex: 'taskNum',
        key: 'taskNum',
        width: 120,
        scopedSlots: { customRender: "taskNum" },
    },
    {
        title: '事件来源',
        dataIndex: 'caseSourceName',
        key: 'caseSourceName',
        width: 100,
    },
    {
        title: '事件类型',
        dataIndex: 'caseTypeName',
        key: 'caseTypeName',
        width: 120,
    },
    {
        title: '上报时间',
        dataIndex: 'createTime',
        key: 'createTime',
        width: 120,
    },
    {
        title: '事件地点',
        dataIndex: 'address',
        key: 'address',
        scopedSlots: { customRender: "ell" },
        width: 240,
    },
    {
        title: '上报人',
        dataIndex: 'uname',
        key: 'uname',
        width: 140,
    },
    {
        title: '事件描述',
        dataIndex: 'eventDesc',
        key: 'eventDesc',
        scopedSlots: { customRender: "ell" },
        width: 180,
    },
    {
        title: '案件状态',
        dataIndex: 'nowCaseProcessNodeName',
        key: 'nowCaseProcessNodeName',
        width: 100,
    }
]
const searchs = [
    {
        code: 'caseSourceName',
        name: '事件来源',
    },
    {
        code: 'caseTypeName',
        name: '事件类型',
    },
    {
        code: 'createTime',
        name: '上报时间',
    },
    {
        code: 'nowCaseProcessNodeName',
        name: '事件状态',
    },
    {
        code: 'address',
        name: '事件地点',
    },
    {
        code: 'uname',
        name: '上报人',
    },
    {
        code: 'eventDesc',
        name: '事件描述',
    },
]
export default {
    name: 'Event',
    props: {
        item: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        const pageSize = 10
        const _this = this
        return {
            DetailForm,
            images: {
                rongqizujian_PRC51E9: `${window.baseUrl}oss-images/20230105/1672911526PZFr.png`,
                zuocebiaoti_PP3JSvG: `${window.baseUrl}oss-images/20230922/1695379525PLcG.png`,
                biaotiqu_PdBmtVL: `${window.baseUrl}oss-images/20240202/1706843682Pm8M.png`,
                biaogezhanwei_PyOXSgN: `${window.baseUrl}oss-images/20240202/1706843981PayG.png`,
                biaogequ_PTMV6WH: `${window.baseUrl}oss-images/20240202/1706843911PWKJ.png`,
                biaotiqu_P5uluCi: `${window.baseUrl}oss-images/20240202/1706844057PEq6.png`,
                biaogezhanwei_PeNnD0Z: `${window.baseUrl}oss-images/20240202/1706843981PayG.png`,
                biaogequ_P9N2pgg: `${window.baseUrl}oss-images/20240202/1706843911PWKJ.png`,
                genjiedian_PC8KBCc: `${window.baseUrl}oss-images/20240202/1706844464P6mU.jpg`,
            },
            P1EykbM: {
                "content": "事件列表"
            },
            event: {
                taskNum: '',
                list: [],
                listId: null,
                columns: [],
                formData: {},
                activeItem: null,
                pages: {
                    pageSize,
                    pageNum: 0,
                },
                pagination: {
                    pageSize,
                },
                info: null,
                searchs: [],
                fields: [],
                filterInputs: [],
            },
            rowClick(record) {
                return {
                    style: {
                        // 行背景色
                        'background-color': record === _this.event.activeItem ? 'rgba(88, 143, 226, 0.45)' : 'rgba(88, 143, 226, 0)',
                    },
                    on: {
                        click() {
                            _this.event.activeItem = record
                            _this.getDetail()
                        }
                    }
                }
            }
        }
    },
    created() {
        this.init()
        window.ve = this
    },
    mounted() {
    },
    methods: {
        init() {
            this.event.taskNum = this.$route.query.taskNum
            
            event_server_banli_listConfig({ listCode: 'event' }).then(res => {
                if (res.ok) {
                    this.event.listId = res.data.id
                    this.event.fields = res.data.fields
                    this.event.searchs = res.data.searchs
                    this.req()
                }
            })
        },
        apiRequest(url, params, { filterFromData, name }) {
            let item = this.getItem(name);
            let dependParams = {}
            item.depend?.forEach(depend => {
                dependParams[depend.paramKey] = filterFromData[depend.code];
            });
            return vuecc.http.post(url, {
                ...params,
                ...dependParams,
            });
        },
        req() {
            if (this.event.formData._is_reset) {
                this.event.taskNum = null
            }
            event_server_banli_list({
                ...this.event.pages,
                listId: this.event.listId,
                id: this.item?.id,
                conditions: {
                    ...this.event.formData,
                    search: this.event.taskNum
                },
            }).then(res => {
                if (res.ok) {
                    const pagination = {
                        ...this.event.pagination,
                        ...this.pages
                    };
                    pagination.total = res.page.count
                    this.event.pagination = pagination
                    this.P1EykbM.content = `事件列表 (${res.page.count})`
                    this.event.list = res.list.map(el => {
                        return {
                            ...el
                        }
                    })
                    this.setSearch()
                    this.setColumns()
                    if (res.list.length) {
                        this.$nextTick(() => {
                            this.$refs.eventTable.$el.querySelector('tr.ant-table-row').click()
                        })
                    }
                }
            })
        },
        eventChange(pagination, filters, sorter) {
            const pager = { ...this.pagination };
            pager.current = pagination.current;
            this.pagination = pager;
            this.event.pages.pageNum = pager.current - 1
            this.req();
        },
        setColumns() {
            let columnMap = {};
            columns.forEach(column => {
                columnMap[column.key] = column;
            });
            let columns_new = [];
            this.event.fields.forEach(field => {
                if (field.isShow) {
                    if (columnMap[field.fieldId]) {
                        columns_new.push(columnMap[field.fieldId]);
                    }
                }
            });

            columns_new.push({
                title: '操作',
                dataIndex: '_action',
                key: '_action',
                width: 80,
                scopedSlots: { customRender: "action" },
            })
            this.event.columns = columns_new
        },
        setSearch() {
            const search_new = []
            searchs.forEach(el => {
                const search = this.event.searchs.find(ele => ele.code === el.code)
                search_new.push({
                    type: search.type,
                    props: {
                        ...search,
                        label: el.name,
                        name: search.code,
                    },
                })
            })
            this.event.filterInputs = search_new
        },
        getItem(name) {
            return this.event.searchs.find(item => item.code == name);
        },
        getDetail() {
            this.event.info = null
            event_server_banli_detail({ id: this.event.activeItem.id, }).then(res => {
                if (res.ok) {
                    this.event.info = res.data
                    this.setDetail()
                }
            })
        },
        setDetail() { },
        onDetail(record) {
            console.log(record.taskNum)
            this.$refs.detailForm.open(record, () => {
                console.log('>>> close')
            });
            // window.open('/fivedoor/vue/pc/event/#/whole_list_system_event?view_module_id=event_new&task_num=' + record.taskNum)
        },
        onSearch(formData) {
            this.event.formData = formData
            this.req()
        },
        onBack() {
            if (this.$router) {
                this.$router.back()
            } else {
                this.$parent.close()
            }
        },
        getBackground(name) {
            return {
                backgroundImage: `url(${this.images[name]})`
            }
        },
    },
    beforeDestroy() {
    },
}
