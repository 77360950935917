
import {is_mobile} from "../../a-hybrid/utils";

export default {
  name: "stage-info-action",
  props:{
    actions:{
      type:Array,
      default(){
        return []
      }
    },
    title:'',
  },
  data(){
    return {
      is_mobile:is_mobile(),
      columns:[
        {
          title: "操作名称",
          dataIndex: "actionName"
        },
        {
          title: "办理时间",
          dataIndex: "createTime"
        },
        {
          title: "办理部门",
          dataIndex: "deptName"
        },
        {
          title: "办理人",
          dataIndex: "uname"
        },
        {
          title: "备注",
          dataIndex: "remark"
        },

      ]
    }
  },
  methods:{
    open(){
      this.$refs.modal.open();
    }
  }
}
