
import DetailInfo from "./info";

import {caseHandle} from "@api";
import {DetailParams} from "./abase/DetailParams";
import DetailTrace from "./trace";
import HybridSkeleton from "../a-hybrid/lib/hybrid-skeleton";
import HybridEmpty from "../a-hybrid/lib/hybrid-empty";
import {is_mobile} from "../a-hybrid/utils";
import SuperviseList from "./supervise-list";
import AttachmentInfo from "./attachment-info";

export default {
  name: "whole-detail",
  components: {AttachmentInfo, SuperviseList, HybridEmpty, HybridSkeleton, DetailTrace,  DetailInfo},
  props: {
    id: 0,
    listId: 0,
    listCode: '',
    is_read:{
      type:Boolean,
      default:false,
    },
    is_cmd_detail:Boolean,
    serverUrl: undefined,
    formCode: undefined,
  },
  provide() {
    if (this.serverUrl) {
      return {
        uniteInfo: {
          serverUrl: this.serverUrl,
          baseServerUrl:"/" + this.serverUrl.split('/')[1] + "/",
          formCode: this.formCode,
          // name:"巡查",
        }
      }
    }
  },
  beforeMount() {

  },
  watch: {
    id: {
      immediate: true,
      handler() {
        console.log(this.id);
        this.getData();
      }
    }
  },
  data() {
    return {
      backToApp:this.$route.query.is_from_app,
      is_assigned_by_leader:this.$route.query.is_assigned_by_leader,
      caseTitle: '',
      isLoading: true,
      loadOk:false,
      detail_info: {},
      detail_params: new DetailParams({
        listId: this.listId,
        listCode: this.listCode,
        is_cmd_detail: this.is_cmd_detail,
      }),
      is_mobile:is_mobile(),
      active_tab:1,
      has_err:false,
    }
  },
  beforeCreate: function () {
  },
  methods: {
    getData() {
      if (!this.id) {
        return;
      }
      this.isLoading = true;
      caseHandle.banli.detail({
        id: this.id,
        listId: this.detail_params.listId,
        listCode:this.detail_params.listCode,
        // isTest:true,
      }).then(res => {
        if (res.ok) {
          this.has_err = false;
          this.detail_info = res.data;
          this.caseTitle =  res.data.taskNum;
          this.isLoading = false;
          this.loadOk = true;
        }else{
          this.$message.error('网络错误，请稍后重试');
          this.isLoading = false;
          this.has_err = true;
        }
      })


    },
    submitOk(params) {

      if(is_mobile()){
        this.getData();
      }else{
        this.$emit('submitOk', params)
      }
    },
    changeTab(tab){
      this.active_tab = tab.id;
    }
  }
}
