
import HybridIcon from "../../../a-hybrid/lib/hybrid-icon";
import {is_mobile} from "../../../a-hybrid/utils";


export default {
  name: "info-block-area",
  components: {HybridIcon},
  props: {
    title: '',

    group: {
      type: Boolean,
      default: false,
    },
    has_history: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      open_group: false,
      is_mobile: is_mobile(),
    }
  }
}
