
import {is_mobile} from "../../../a-hybrid/utils";

export default {
  name: "info-no-data",
  data(){
    return {
      is_mobile:is_mobile(),
    }
  }
}
