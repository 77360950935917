
    import {DetailParams} from "../abase/DetailParams";
    import {caseHandle, processConfServerListConfig} from "@api";
    import InfoReport from "./info-report";
    import InfoBasic from "./info-basic";
    import InfoDispose from "./info-dispose";
    import InfoCheck from "./info-check";
    import InfoApply from "./info-apply";
    import InfoCancel from "./info-cancel";
    import InfoContact from "./info-contact";
    import InfoHang from "./info-hang";
    import InfoOther from "./info-other";
    import InfoRollback from "./info-rollback";
    import InfoTransferInfos from "./info-transferInfos";
    import InfoDisposeApproval from "./info-disposeApproval";
    import InfoApplyApproval from "./info-applyApproval";
    import InfoAcceptInfos from "./info-acceptInfos";
    import InfoSupervise from "./info-supervise";
    import InfoVerify from "./info-verify";
    import {is_mobile} from "../../a-hybrid/utils";
    import CaseAlarm from "../../base/case-alarm";
    import moment from "moment";
    import InfoDifficult from "./info-difficult";
    import InfoExternalresultback from "./info-externalresultback";
    import InfoExternalback from "./info-externalback";

    export default {
        name: "detail-info",
        components: {
            InfoDifficult,
            CaseAlarm,
            InfoVerify,
            InfoSupervise,
            InfoRollback,
            InfoOther,
            InfoHang,
            InfoContact,
            InfoTransferInfos,
            InfoAcceptInfos,
            InfoCancel,
            InfoApply,
            InfoCheck,
            InfoDispose,
            InfoBasic,
            InfoDisposeApproval,
            InfoReport,
            InfoExternalresultback,
            InfoExternalback,
            InfoApplyApproval,
        },
        props: {
            detail_info: {
                type: Object,
                default() {
                    return {};
                }
            },
            detail_params: DetailParams,
        },

        computed: {
            show_dispose() {
                return !!this.detail_info.disposeInfo && !this.show_check;
            },
            show_check() {
                return !!this.detail_info.checkInfo;
            },
            regularTimeDesc() {
                return this.detail_info.regularTime ? moment(this.detail_info.regularTime * 1000).format('YYYY-MM-DD HH:mm:ss') : '';
            }
        },
        data() {
            return {
                layout_list: [],
                is_mobile: is_mobile(),
            }
        },
        created() {

            this.layout_list = [
                {"id": "report", "name": "上报信息", "info_show_type": 1, "span": 1},
                // {
                //     "id": "basic",
                //     "name": "基础信息",
                //     "info_show_type": 2,
                //     "span": 1
                // },
                // {"id": "verify", "name": "核实信息", "info_show_type": 1, "span": 1},
                // {
                //     "id": "check",
                //     "name": "核查信息",
                //     "info_show_type": 1,
                //     "span": 1
                // },
                {"id": "dispose", "name": "处办信息", "info_show_type": 1, "span": 1},
                {
                    "id": "disposeApproval",
                    "name": "处办审核信息",
                    "info_show_type": 1,
                    "span": 1
                },

                {"id": "acceptInfos", "name": "受理信息", "info_show_type": 1, "span": 1},
                {
                    "id": "apply",
                    "name": "申请信息",
                    "info_show_type": 1,
                    "span": 1
                },
                {
                    "id": "applyApproval",
                    "name": "申请审核信息",
                    "info_show_type": 1,
                    "span": 1
                },
                {"id": "transferInfos", "name": "移交", "info_show_type": 1, "span": 1},

                // {"id": "rollback", "name": "回退信息", "info_show_type": 2, "span": 1},
                // {
                //     "id": "cancel",
                //     "name": "作废信息",
                //     "info_show_type": 2,
                //     "span": 1
                // },
                // {"id": "other", "name": "其他信息", "info_show_type": 2, "span": 1},
                // {
                //     "id": "hang",
                //     "name": "挂账信息",
                //     "info_show_type": 2,
                //     "span": 1
                // }
            ];

        },
        methods: {
            submitOk(params) {

                this.$emit('submitOk', params)
            },
            getDetailParams(layout) {
                let detailParams = new DetailParams(this.detail_params);
                detailParams.layout = layout;
                return detailParams
            }
        }

    }
