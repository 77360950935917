
    import {DetailParams} from "../../abase/DetailParams";
    import InfoItem from "../base/info-item";
    import InfoBlockArea from "../base/info-block-area";
    import InfoGroup from "../base/info-group";
    import CaseImagePreview from "../../../base/case-image-preview";
    import {is_mobile} from "../../../a-hybrid/utils";
    import {RequisitionTypeEnum} from "@enum/CaseEnum";
    import {useInfoImage} from "../base/useInfoImage";
    import InfoNoData from "../base/info-no-data";
    import InfoPeoplePhone from "../base/info-people-phone";

    export default {
        name: "info-apply",
        components: {InfoNoData, CaseImagePreview, InfoGroup, InfoBlockArea, InfoItem,InfoPeoplePhone},
        props: {
            detail_info: {
                type: Object,
                default() {
                    return {};
                }
            },
            detail_params: DetailParams,
        },
        mounted(){
            if(this.detail_info.applyInfos){
                for(let item of this.detail_info.applyInfos){
                    if( item.approves  &&  item.approves.length > 0){
                        this.hasData = true;
                        break
                    }

                }
            }

        },
        computed: {},
        setup(props) {
            let {image_up, image_down, text_show} = useInfoImage(props.detail_params)
            return {
                image_up,
                image_down,
                text_show,
            }
        },
        data() {
            return {
                hasData:false,
                is_mobile: is_mobile(),
                RequisitionTypeEnum,
                numberHanzi:["一","二","三","四","五","多"]

            }
        },
        methods: {
            content_color(applyInfo) {
                //1.待审核,2.审核通过,3.审核不通过
                return applyInfo.isPass? '#52c41a' : 'red';
            },
        }
    }
