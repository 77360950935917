
import moment from "moment";
import CaseTag from "./case-tag";

export default {
  name: "case-alarm",
  components: {CaseTag},
  props:{
    alarms:{
      type:Array,
      default(){
        return []
      }
    },
    show_label:{
      type:Boolean,
      default:false
    },
    is_detail:Boolean,
  },
  watch:{
    alarms:{
      immediate:true,
      handler(){
      }
    }
  },
  data(){
    return {
      time_str:'-',
      nodeName:'',
    }
  },
  created() {
    this.interval = setInterval(() => {

      let endTime = 0;
      let color = '';
      if(this.alarms.length){
        if(this.show_label){
          this.time_str = '倒计时：'
        }else{
          this.time_str = ''
        }
        if(this.is_detail){
          this.time_str = '截止时间：';
        }
        this.nodeName = this.alarms[0].name;
        let yellowTime = this.alarms[0].yellowTime;
        endTime = this.alarms[0].endTime;
        if(new Date().getTime() > yellowTime*1000){
          color = '#ff9100';
        }
        if(new Date().getTime() > endTime*1000){
          color = '#FF4D4F';
        }
      }

      if(endTime){
        let start = moment(new Date());//获取开始时间
        let end = moment(endTime*1000);//结束时间
        if(end < start){
          this.time_str += '<span style="color: '+color+';">已超时</span>';
          return;
        }
        let diff = end.diff(start);//时间差
        let duration = moment.duration(diff);
        let day_num = parseInt(duration.asDays());
        let day_str = '';
        if(day_num>0){
          day_str = `<span style="color: ${color}">${day_num}</span>天`;
        }
        this.time_str += `${day_str}<span style="color: ${color}">${duration.hours()}</span>时<span style="color: ${color}">${duration.minutes()}</span>分<span style="color: ${color}">${duration.seconds()}</span>秒`//格式化为需要的格式 这里是时分秒
      }

    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.interval);
  }
}
