
    import {DetailParams} from "../../abase/DetailParams";
    import {CaseTypeEnum} from "@enum/CaseEnum";
    import InfoItem from "../base/info-item";
    import InfoPeoplePhone from "../base/info-people-phone";

    import InfoBlockArea from "../base/info-block-area";
    import CaseImagePreview from "../../../base/case-image-preview";
    import {is_mobile} from "../../../a-hybrid/utils";
    import {useInfoImage} from "../base/useInfoImage";
    import HybridMapShow from "../../../a-hybrid/hybrid-map-show";
    import HybridButton from "../../../a-hybrid/lib/hybrid-button";
    import {caseHandle} from "@api";
    import {customSchemaConfig} from "@api";


    export default {
        name: "info-report",
        components: {CaseImagePreview, InfoBlockArea, InfoItem,HybridMapShow,HybridButton,InfoPeoplePhone},
      inject:{
        uniteInfo:{
          default:undefined
        }
      },
      props: {
            detail_info: {
                type: Object,
                default() {
                    return {};
                }
            },
            detail_params: DetailParams,
        },
        setup(props) {
            let {image_up, image_down, text_show} = useInfoImage(props.detail_params)
            return {
                image_up,
                image_down,
                text_show,
            }
        },
        computed: {
            eventTypeName() {
                return CaseTypeEnum.getValueByIndex(this.detail_info.eventTypeId);
            },
            mainSubName() {
                return this.detail_info.mainTypeName + ' - ' + this.detail_info.subTypeName;
            }
        },
        data() {
            return {
              customData:{},
              uniteCustomData:{}
            }
        },
        mounted(){
            customSchemaConfig.list({}).then(res => {
                if (res.ok) {
                    this.customData = res.list
                }
            });


          if (this.uniteInfo) {
            this.$api.unite.customSchemasForDetail(this.uniteInfo,{id:this.detail_info.id}).then((res)=>{
                this.uniteCustomData = res.list
            })
          }
        },
        methods:{
            showMap(){
              if(is_mobile()){
                this.$webSdk.jumpThirdPartyMapNavigation({
                  params: {
                    target_location: {
                      lat: this.detail_info.lat,
                      lng: this.detail_info.lng,
                      address:this.detail_info.address
                    },
                    des:  this.detail_info.address
                  }
                })
              }else{
                this.$refs.map.open();
              }
            },
            clickImportant(status){
                if(status){
                    caseHandle.banli.markCancelImportant({
                        id:this.detail_info.id
                    }).then((res)=>{
                        this.$emit('submitOk');

                    })
                }else{
                    caseHandle.banli.markImportant({
                        id:this.detail_info.id
                    }).then((res)=>{
                        this.$emit('submitOk');

                    })
                }

            }

        }
    }
