
import { createAxios } from "../../http"

const apiRequest = {
	http:createAxios()
};

export const hostURL = window.apiconfig.VUE_APP_SZGY_hostURL;
export const adminURL = window.apiconfig.VUE_APP_SZGY_adminURL;

apiRequest.http.defaults.baseURL = adminURL;
apiRequest.http.interceptors.request.use(    function(config) {
	const token = window.apiconfig.VUE_APP_SZGY_Token
			let param = config.data || config.params || {};
			if (param.baseUrl) {
				config.baseURL = param.baseUrl;
				delete param.baseUrl;
			}
			// 处理ses token
			if(token && config.method !== "get"){
				config.headers['ses-token'] = token;
			}

	
			if (config.method === "post") {
				if(!config.data){
					config.data = {};
				}
				config.data.ses_token = token;
			}else{
				if(!config.params){
					config.params = {};
				}
				config.params.ses_token = token;
			}
			return config;
		},
		function(error) {
			return Promise.reject(error);
		})
export default apiRequest;