import apiZhjg from "./apiZhjg";
export const caseHandle = {
    banli:{
        list:params =>  apiZhjg.http.post("/event_server/banli/list", params),
        add:params =>  apiZhjg.http.post("/event_server/banli/add", params),
        detail:params =>  apiZhjg.http.post("/event_server/banli/detail", params),
        nextNodes:params =>  apiZhjg.http.post("/event_server/banli/nextNodes", params),
        disposeUsers:params =>  apiZhjg.http.post("/event_server/banli/disposeUsers", params),
        action:params =>  apiZhjg.http.post("/event_server/banli/action", params),
        actionExtendInfo:params =>  apiZhjg.http.post("/event_server/banli/actionExtendInfo", params),
        createAction:params =>  apiZhjg.http.post("/event_server/banli/createAction", params),
        mark:params =>  apiZhjg.http.post("/event_server/banli/mark", params),
        listUserRelation:params =>  apiZhjg.http.post("/event_server/banli/listUserRelation", params),
        actionBatch:params =>  apiZhjg.http.post("/event_server/banli/actionBatch", params),
        dispatchTargets:params =>  apiZhjg.http.post("/event_server/banli/dispatchTargets", params),
        isActioned:params =>  apiZhjg.http.post("/event_server/banli/isActioned", params),
        dispatchAreaDeptList:params =>  apiZhjg.http.post("/event_server/banli/dispatchAreaDeptList", params),
        dispatchAreaList:params =>  apiZhjg.http.post("/event_server/banli/dispatchAreaList", params),
        dispatchOneKeyInfo:params =>  apiZhjg.http.post("/event_server/banli/dispatchOneKeyInfo", params),
        markImportant:params =>  apiZhjg.http.post("/event_server/banli/markImportant", params),
        markCancelImportant:params =>  apiZhjg.http.post("/event_server/banli/markCancelImportant", params),
        listConfig:params =>  apiZhjg.http.post("/event_server/banli/listConfig", params),
        batchDownload:params =>  apiZhjg.http.post("/event_server/banli/batchDownload", params),
        draft:params =>  apiZhjg.http.post("/event_server/banli/draft", params),
        draftList:params =>  apiZhjg.http.post("/event_server/banli/draftList", params),
        draftDel:params =>  apiZhjg.http.post("/event_server/banli/draftDel", params),
        listSimilar:params =>  apiZhjg.http.post("/event_server/banli/listSimilar", params),
        listSimilarReport:params =>  apiZhjg.http.post("/event_server/banli/listSimilarReport", params),
        distributionInfo:params =>  apiZhjg.http.post("/event_server/banli/distributionInfo", params),
        distributionAction:params =>  apiZhjg.http.post("/event_server/banli/distributionAction", params),
        listTabs:params =>  apiZhjg.http.post("/event_server/banli/listTabs", params),
    },
}


export const processConfServerZhAnalysis = {
    overview:params =>  apiZhjg.http.post("/event_server/statistic-analysis/overview", params),
    source:params =>  apiZhjg.http.post("/event_server/statistic-analysis/source", params),
    type:params =>  apiZhjg.http.post("/event_server/statistic-analysis/type", params),
    rank:params =>  apiZhjg.http.post("/event_server/statistic-analysis/rank", params),
    trend:params =>  apiZhjg.http.post("/event_server/statistic-analysis/trend", params),
    rankOption:params =>  apiZhjg.http.post("/event_server/statistic-analysis/rankOption", params),


    evaluation:params =>  apiZhjg.http.post("/event_server/zh-analysis/evaluation", params),
    highType:params =>  apiZhjg.http.post("/event_server/zh-analysis/highType", params),
    highArea:params =>  apiZhjg.http.post("/event_server/zh-analysis/highArea", params),
    highTypeTree:params =>  apiZhjg.http.post("/event_server/zh-analysis/highTypeTree", params),
    analysis:params =>  apiZhjg.http.post("/event_server/zh-analysis/analysis", params),
    lowDisposeTree:params =>  apiZhjg.http.post("/event_server/zh-analysis/lowDisposeTree", params),
    lowDisposeTreeTurndown:params =>  apiZhjg.http.post("/event_server/zh-analysis/lowDisposeTreeTurndown", params),
    highCaseTurndown:params =>  apiZhjg.http.post("/event_server/zh-analysis/highCaseTurndown", params),
    highTypeTreeTurndown:params =>  apiZhjg.http.post("/event_server/zh-analysis/highTypeTreeTurndown", params),
    highAreaTurndown:params =>  apiZhjg.http.post("/event_server/zh-analysis/highAreaTurndown", params),
    analysisTurndown:params =>  apiZhjg.http.post("/event_server/zh-analysis/analysisTurndown", params),
};



export const process_conf_server_app_statistic = {
    dynamicCase:params =>  apiZhjg.http.post("/event_server/app-statistic/dynamicCase", params),
    dynamicTodayCase:params =>  apiZhjg.http.post("/event_server/app-statistic/dynamicTodayCase", params),
    dynamicCheck:params =>  apiZhjg.http.post("/event_server/app-statistic/dynamicCheck", params),
    leaderBoardDeptArea:params =>  apiZhjg.http.post("/event_server/app-statistic/leaderBoardDeptArea", params),
    leaderBoardComponentSubType:params =>  apiZhjg.http.post("/event_server/app-statistic/leaderBoardComponentSubType", params),
    leaderBoardHighStreet:params =>  apiZhjg.http.post("/event_server/app-statistic/leaderBoardHighStreet", params),
    leaderBoardEventSubType:params =>  apiZhjg.http.post("/event_server/app-statistic/leaderBoardEventSubType", params),
    leaderBoardSupervisor:params =>  apiZhjg.http.post("/event_server/app-statistic/leaderBoardSupervisor", params),
    leaderBoardEvaluation:params =>  apiZhjg.http.post("/event_server/app-statistic/leaderBoardEvaluation", params),
    leaderBoardSeat:params =>  apiZhjg.http.post("/event_server/app-statistic/leaderBoardSeat", params),
    comprehensiveEvaluation:params =>  apiZhjg.http.post("/event_server/app-statistic/comprehensiveEvaluation", params),
    leaderBoardSupervisorQuotaList:params =>  apiZhjg.http.post("/event_server/app-statistic/leaderBoardSupervisorQuotaList", params),

}

export const process_conf_server_basic = {
    professionalOrgTree:params =>  apiZhjg.http.post("/event_server/basic/professionalOrgTree", params),
}

