
export default {
  name: "info-item",
  props: {
    label: String,
    content: [String, Number, Array],
    contentColor: {
      type: String,
      default: "#595959"
    },
    block:{
      type:Boolean,
      default:false,
    },
    border:{
      type:Boolean,
      default:false,
    },
    mobile_padding:{
      type:Boolean,
      default:false,
    }
  },
  components: {},
  watch: {},
  data() {
    return {};
  },
  methods: {}
};
