import { render, staticRenderFns } from "./info-item.vue?vue&type=template&id=df7dd6ba&scoped=true&"
import script from "./info-item.vue?vue&type=script&lang=js&"
export * from "./info-item.vue?vue&type=script&lang=js&"
import style0 from "./info-item.vue?vue&type=style&index=0&id=df7dd6ba&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "df7dd6ba",
  null
  
)

export default component.exports