
import {DetailParams} from "../abase/DetailParams";
import HybridTimeline from "../../a-hybrid/lib/hybrid-timeline";
import HybridTimelineItem from "../../a-hybrid/lib/hybrid-timeline-item";
import HybridIcon from "../../a-hybrid/lib/hybrid-icon";

export default {
  name: "detail-trace",
  components: {HybridIcon, HybridTimelineItem, HybridTimeline},
  props:{
    detail_info:{
      type:Object,
      default(){
        return {}
      }
    },
    detail_params: DetailParams,
  }
}
