import {Enum} from "xb-lib-view/dist/utils";

export class CasePhaseEnum extends Enum {
  static ENUM_NAME = Symbol("CasePhaseEnum");
  static NORMAL = 1;
  static ALERT = 2;
  static TIMEOUT = 3;

  constructor() {
    super();
  }
  initAddDatas() {
    this.add(CasePhaseEnum.NORMAL, { text: "绿灯" });
    this.add(CasePhaseEnum.ALERT, { text: "橙灯" });
    this.add(CasePhaseEnum.TIMEOUT, { text: "红灯" });
  }
}

export class CaseVerificationEnum extends Enum {
  static ENUM_NAME = Symbol("CaseVerificationEnum");
  static NO_NEED = 1;
  static NOT_YET = 2;
  static DONE = 3;

  constructor() {
    super();
  }
  initAddDatas() {
    this.add(CaseVerificationEnum.NOT_YET, "未核实");
    this.add(CaseVerificationEnum.DONE, "已核实");
    this.add(CaseVerificationEnum.NO_NEED, "无需核实");
  }
}

export class CaseTypeEnum extends Enum {
  static ENUM_NAME = Symbol("CaseTypeEnum");
  static EVENT = 1;
  static UNIT = 2;

  constructor() {
    super();
  }
  initAddDatas() {
    this.add(CaseTypeEnum.EVENT, "事件");
    this.add(CaseTypeEnum.UNIT, "部件");
  }
}

export class CaseAreaEnum extends Enum {
  static ENUM_NAME = Symbol("CaseAreaEnum");
  static AREA = "area";
  static STREET = "road";
  static COMMUNITY = "community";
  static UNIT_GRID = "management_grid";
  static RESPONSIBILITY_GRID = "responsibility_grid";

  constructor() {
    super();
  }
  initAddDatas() {
    // this.add(CaseAreaEnum.AREA, "区");
    this.add(CaseAreaEnum.STREET, "街道");
    this.add(CaseAreaEnum.COMMUNITY, "社区");
    this.add(CaseAreaEnum.UNIT_GRID, "单元网格");
    this.add(CaseAreaEnum.RESPONSIBILITY_GRID, "责任网格");
  }
}

export class CaseSourceEnum extends Enum {
  static ENUM_NAME = Symbol("CaseSourceEnum");
  static SUPERVISOR_REPORT = 2;
  static URGE_REPORT = 6;
  static VIDEO_REPORT = 7;
  static OTHER = 9;
  static SELF_DEAL = 10;

  constructor() {
    super();
  }
  initAddDatas() {
    this.add(CaseSourceEnum.SUPERVISOR_REPORT, "监督员上报");
    this.add(CaseSourceEnum.URGE_REPORT, "督查上报（领导交办）");
    this.add(CaseSourceEnum.VIDEO_REPORT, "视频上报（AI平台上报）");
    this.add(CaseSourceEnum.OTHER, "其他来源");
    this.add(CaseSourceEnum.SELF_DEAL, "自行处置");
  }
}

export class CaseSourceGroupEnum extends Enum {
  static PUBLIC = 1;
  static SMART = 2;
  static GRID = 3;
  static LEADER = 4;
  static OTHER = 5;
//[{id:1,name:'公众服务、热线'},{id:2,name:'智能设备监测'},{id:3,name:'网格巡查'},{id:4,name:'领导交办'},{id:5,name:'其他'}]
  constructor() {
    super();
  }
  initAddDatas() {
    this.add(CaseSourceGroupEnum.PUBLIC, "公众服务、热线");
    this.add(CaseSourceGroupEnum.SMART, "智能设备监测");
    this.add(CaseSourceGroupEnum.GRID, "网格巡查");
    this.add(CaseSourceGroupEnum.LEADER, "领导交办");
    this.add(CaseSourceGroupEnum.OTHER, "其他");
  }
}
export class ShortcutPhaseEnum extends Enum {
  static ENUM_NAME = Symbol("ShortcutPhaseEnum");
  static VERIFICATION_DISPATCH = "send_verify";
  static VERIFICATION = "verify";
  static ACCEPT = "operate";
  static FILE_CASE = "inst";
  static DISPATCH = "dispatch";
  static HANDLE = "dispose";
  static AUDIT_DISPATCH = "send_check";
  static AUDIT = "check";
  static CLOSE_CASE = "archive";
  static APPROVE = "examine_and_verify";
  static BACK_OFF = "back_off";
  static REPLAY_BACK_OFF = "fallback_reply";
  static TRANSMIT = "approval_and_transmission";

  constructor() {
    super();
  }
  initAddDatas() {
    this.add(ShortcutPhaseEnum.VERIFICATION_DISPATCH, "核实派发");
    this.add(ShortcutPhaseEnum.VERIFICATION, "核实");
    this.add(ShortcutPhaseEnum.ACCEPT, "受理");
    this.add(ShortcutPhaseEnum.FILE_CASE, "立案");
    this.add(ShortcutPhaseEnum.DISPATCH, "派遣");
    this.add(ShortcutPhaseEnum.HANDLE, "处置");
    this.add(ShortcutPhaseEnum.AUDIT_DISPATCH, "核查派发");
    this.add(ShortcutPhaseEnum.AUDIT, "核查");
    this.add(ShortcutPhaseEnum.CLOSE_CASE, "结案");
    this.add(ShortcutPhaseEnum.APPROVE, "审核");
    this.add(ShortcutPhaseEnum.BACK_OFF, "回退");
    this.add(ShortcutPhaseEnum.REPLAY_BACK_OFF, "回退答复");
    this.add(ShortcutPhaseEnum.TRANSMIT, "批转");
  }
}
export class SendMessageEnum extends Enum {
  static ENUM_NAME = Symbol("SendMessageEnum");
  static REFUSE = 0;
  static ACCEPT = 1;

  constructor() {
    super();
  }
  initAddDatas() {
    this.add(SendMessageEnum.REFUSE, "否");
    this.add(SendMessageEnum.ACCEPT, "是");
  }
}

export class CaseListTypeEnum extends Enum {
  static ENUM_NAME = Symbol("CaseListTypeEnum");
  static EXPORT_OFFSET = 1000;
  static SEARCH_OFFSET = 500;
  static SIGNIN_OTHER = 1;
  static SIGNIN_AI = 2;
  static SIGNIN_PARTICULAR = 3;
  static TODO_FILE_CASE = 4;
  static TODO_DISPATCH = 5;
  static TODO_AUDIT = 6;
  static TODO_APPROVE = 7;
  static TODO_CLOSE_CASE = 8;
  static HARD_CASE = 9;
  static PREVARICATION_CASE = 10;
  static SELF_HANDLE = 11;
  static MY_DEALT = 12;
  static INVALID_CASE = 13;
  static TODO_HANDLE = 14;
  static TODO_DOUBLE = 64;
  static TODO_SPECIALDEPT_AUDIT = 15;
  static SUSPENSE_CASE = 16;
  static URGE_CASE = 17;
  static ARCHIVE_CASE = 18;
  static TYPICAL_CASE = 19;
  static ADVANCE_SEARCH = 20;
  static CASE_MANAGEMENT = 21;
  static SPECIAL_CASE = 23;
  static PREHANDLE_CASE = 24;
  static SPECIAL_INSPECTION = 25;
  static SPECIAL_INSPECTION_BOOK = 26;
  static ASSESS_POSITION_REVERSE = 51;
  static ASSESS_AREA_REVERSE = 52;
  static ASSESS_DEPARTMENT_REVERSE = 53;
  static ASSESS_COMPREHENSIVE_REVERSE = 54;
  static NEW_CASE_URGE = 55;
  static REPLY_CASE_URGE = 56;
  static SIMILAR_CASE = 57;
  static UH_CASE_APPROVE = 58;
  static UH_CASE_MANAGEMENT = 59;
  static PARALLEL_CASE = 29;
  static VIOLATION_CASE = 30;
  //回访
  static WAIT_REPLY = 61;
  static HAD_REPLIED = 62;
  static NO_NEED_REPLY = 63;
  //待派遣
  static WAIT_REGULAR_TIME_DISPATCH = 31;

  constructor() {
    super();
  }
  initAddDatas() {
    this.add(CaseListTypeEnum.SIGNIN_OTHER, "签收池-其他来源案件");
    this.add(CaseListTypeEnum.SIGNIN_AI, "签收池-AI平台案件");
    this.add(CaseListTypeEnum.SIGNIN_PARTICULAR, "签收池-特别案件");
    this.add(CaseListTypeEnum.TODO_FILE_CASE, "我的待办-待立案");
    this.add(CaseListTypeEnum.TODO_DISPATCH, "我的待办-待派遣");
    this.add(CaseListTypeEnum.TODO_AUDIT, "我的待办-待核查");
    this.add(CaseListTypeEnum.TODO_APPROVE, "我的待办-待审核");
    this.add(CaseListTypeEnum.TODO_CLOSE_CASE, "我的待办-待结案");
    this.add(CaseListTypeEnum.HARD_CASE, "疑难案件");
    this.add(CaseListTypeEnum.PREVARICATION_CASE, "推诿案件");
    this.add(CaseListTypeEnum.SELF_HANDLE, "自行处置案件");
    this.add(CaseListTypeEnum.MY_DEALT, "我的经办");
    this.add(CaseListTypeEnum.INVALID_CASE, "作废案件");
    this.add(CaseListTypeEnum.TODO_HANDLE, "我的待办-待处置");
    this.add(
      CaseListTypeEnum.TODO_SPECIALDEPT_AUDIT,
      "我的待办-待专业部门审核"
    );
    this.add(CaseListTypeEnum.SUSPENSE_CASE, "挂账案件");
    this.add(CaseListTypeEnum.URGE_CASE, "督办案件");
    this.add(CaseListTypeEnum.ARCHIVE_CASE, "归档案件");
    this.add(CaseListTypeEnum.TYPICAL_CASE, "特殊案件");
    this.add(CaseListTypeEnum.ADVANCE_SEARCH, "高级查询");
    this.add(CaseListTypeEnum.CASE_MANAGEMENT, "案件管理");
    this.add(CaseListTypeEnum.SPECIAL_CASE, "专案案件");
    this.add(CaseListTypeEnum.PREHANDLE_CASE, "预处理案件");
    this.add(CaseListTypeEnum.SPECIAL_INSPECTION, "专项巡查案件");
    this.add(CaseListTypeEnum.SPECIAL_INSPECTION_BOOK, "专项巡查台账");
    this.add(CaseListTypeEnum.ASSESS_POSITION_REVERSE, "岗位评价反查列表");
    this.add(CaseListTypeEnum.ASSESS_AREA_REVERSE, "区域评价案件反查列表");
    this.add(
      CaseListTypeEnum.ASSESS_DEPARTMENT_REVERSE,
      "部门评价案件反查列表"
    );
    this.add(
      CaseListTypeEnum.ASSESS_COMPREHENSIVE_REVERSE,
      "综合评价案件反查列表"
    );
    this.add(CaseListTypeEnum.NEW_CASE_URGE, "督办案件-案件督办");
    this.add(CaseListTypeEnum.REPLY_CASE_URGE, "督办案件-答复督办");
    this.add(CaseListTypeEnum.SIMILAR_CASE, "相似案件");
    this.add(CaseListTypeEnum.UH_CASE_APPROVE, "疑难案件审核");
    this.add(CaseListTypeEnum.UH_CASE_MANAGEMENT, "疑难案件管理");
    this.add(CaseListTypeEnum.PARALLEL_CASE, "并行案件");
    this.add(CaseListTypeEnum.VIOLATION_CASE, "违规违停告警案件");

    this.add(CaseListTypeEnum.WAIT_REPLY, "待回访案件");
    this.add(CaseListTypeEnum.HAD_REPLIED, "已回访案件");
    this.add(CaseListTypeEnum.NO_NEED_REPLY, "无需回访案件");

    this.add(CaseListTypeEnum.WAIT_REGULAR_TIME_DISPATCH, "待派遣");
  }
}

export class ShortcutScopeEnum extends Enum {
  static ENUM_NAME = Symbol("ShortcutScopeEnum");
  static GLOBAL_ONLY = 0;
  static MAIN_ONLY = 1;
  static ALL = 2;

  constructor() {
    super();
  }
  initAddDatas() {
    this.add(ShortcutScopeEnum.GLOBAL_ONLY, "仅全局");
    this.add(ShortcutScopeEnum.MAIN_ONLY, "仅自己");
    this.add(ShortcutScopeEnum.ALL, "所有");
  }
}
export class CaseTimeDegreeEnum extends Enum {
  static ENUM_NAME = Symbol("CaseTimeDegreeEnum");
  static EMERGENCY = 1;
  static IMPORTANT = 2;
  static NORMAL = 3;

  constructor() {
    super();
  }
  initAddDatas() {
    this.add(CaseTimeDegreeEnum.EMERGENCY, "A类(紧急)");
    this.add(CaseTimeDegreeEnum.IMPORTANT, "B类(重大)");
    this.add(CaseTimeDegreeEnum.NORMAL, "C类(一般)");
  }
}

export class RequisitionTypeEnum extends Enum {
  static ENUM_NAME = Symbol("RequisitionTypeEnum");
  static BACK = 1;
  static DELAY = 2;
  static SUSPENSE = 3;
  // static HANDLE = 4;
  static WEI_ZHUAN_HU = 4;
  static INVALID = 5;
  static RES = 6;

  constructor() {
    super();
  }
  initAddDatas() {
    this.add(RequisitionTypeEnum.BACK, "回退");
    this.add(RequisitionTypeEnum.DELAY, "延期");
    this.add(RequisitionTypeEnum.SUSPENSE, "挂账");
    // this.add(RequisitionTypeEnum.WEI_ZHUAN_HU, "围转护");
    // this.add(RequisitionTypeEnum.INVALID, "作废");
  }
}

export class RequisitionJudgeEnum extends Enum {
  static YSE = 1;
  static NO = 2;

  constructor() {
    super();
  }
  initAddDatas() {
    this.add(RequisitionJudgeEnum.YSE, "审核通过");
    this.add(RequisitionJudgeEnum.NO, "审核不通过");
  }
}



//	审批方式信息所需的 ApplyType
export class ApplyTypeEnum extends Enum {
  static ENUM_NAME = Symbol("RequisitionTypeEnum");
  static BACK = 1;
  static DELAY = 2;
  static SUSPENSE = 3;
  // static HANDLE = 4;
  static WEI_ZHUAN_HU = 4;
  static INVALID = 5;
  static RES = 6;

  constructor() {
    super();
  }
  initAddDatas() {
    this.add(ApplyTypeEnum.BACK, "回退");
    this.add(ApplyTypeEnum.DELAY, "延期");
    this.add(ApplyTypeEnum.SUSPENSE, "挂账");
    // this.add(ApplyTypeEnum.WEI_ZHUAN_HU, "围转护");
    // this.add(ApplyTypeEnum.INVALID, "作废");
    this.add(ApplyTypeEnum.RES, "处办结果");
  }
}

export class ApproveTypesEnum extends Enum {
  static MULTI = 1;
  static PRE = 2;
  static FIRST = 3;

  constructor() {
    super();
  }
  initAddDatas() {
    this.add(ApproveTypesEnum.MULTI, "按指派的多层级审核");
    this.add(ApproveTypesEnum.PRE, "仅由上一个指派部门审核");
    this.add(ApproveTypesEnum.FIRST, "仅由第一个指派部门审核");
  }
}

export class ApproveStatusEnum extends Enum {
  static NONE = 0;
  static APPLY = 1;    //申请审核
  static DISOPSE = 2;  //处置审核

}



export class EventStateEnum extends Enum {
  static ENUM_NAME = Symbol("EventStateEnum");
  static WAIT_SIGN = 21;
  static WAIT_INST = 2;
  static WAIT_VERIFY = 22;
  static WAIT_DISPATCH = 6;
  static WAIT_DISPOSE = 8;
  static WAIT_SEND_CHECK = 24;
  static WAIT_CHECK = 13;
  static WAIT_ARCHIVE = 14;
  static ARCHIVED = 101;
  static CANCELED = 102;
  static WAIT_APPROVE = 4;
  static WAIT_CLAIM = 23;

  constructor() {
    super();
  }
  initAddDatas() {
    this.add(EventStateEnum.WAIT_SIGN, "待签收");
    this.add(EventStateEnum.WAIT_INST, "待立案");
    this.add(EventStateEnum.WAIT_VERIFY, "待核实");
    this.add(EventStateEnum.WAIT_DISPATCH, "待派遣");
    this.add(EventStateEnum.WAIT_DISPOSE, "待处置");
    this.add(EventStateEnum.WAIT_SEND_CHECK, "待核查派发");
    this.add(EventStateEnum.WAIT_CHECK, "待核查");
    this.add(EventStateEnum.WAIT_ARCHIVE, "待结案");
    this.add(EventStateEnum.ARCHIVED, "已结案");
    this.add(EventStateEnum.CANCELED, "已作废");
    this.add(EventStateEnum.WAIT_APPROVE, "待审核");
    this.add(EventStateEnum.WAIT_CLAIM, "待认领");
  }
}

export class ApproveStateEnum extends Enum {
  static ENUM_NAME = Symbol("ApproveStateEnum");
  static WAITING = 1;
  static PASS = 2;
  static FAIL = 3;

  constructor() {
    super();
  }
  initAddDatas() {
    this.add(ApproveStateEnum.WAITING, "待审核");
    this.add(ApproveStateEnum.PASS, "审核通过");
    this.add(ApproveStateEnum.FAIL, "审核不通过");
  }
}

export class CaseTableColumnEnum extends Enum {
  static ENUM_NAME = Symbol("CaseTableColumnEnum");
  /**
   * 案件编号
   */
  static TASK_NUM = "task_num";

  /**
   * 环节状态
   */
  static CASE_STAGE_STATE = "case_stage_state";

  /**
   * 截止时间倒计时
   */
  static CASE_STAGE_DEADLINE = "case_stage_deadline";

  /**
   * 问题描述
   */
  static EVENT_DESC = "event_desc";

  /**
   * 类型
   */
  static EVENT_TYPE_NAME = "event_type_name";

  /**
   * 大类
   */
  static MAIN_TYPE_NAME = "main_type_name";

  /**
   * 小类
   */
  static SUB_TYPE_NAME = "sub_type_name";

  /**
   * 案件状态
   */
  static EVENT_STATE_NAME = "event_state_name";

  /**
   * 上报时间
   */
  static CREATE_TIME_DESC = "create_time_desc";

  /**
   * 案件来源
   */
  static EVENT_SRC_TYPE_NAME = "event_src_type_name";

  /**
   * 所属区域
   */
  static FULL_DISTRICT_NAME = "full_district_name";

  /**
   * 案件位置
   */
  static ADDRESS = "address";

  /**
   * 核实人
   */
  static VERIFY_USER_NAME = "verify_user_name";

  /**
   * 核实时间
   */
  static VERIFY_TIME_DESC = "verify_time_desc";

  /**
   * 派遣时间
   */
  static DISPATCH_TIME_DESC = "dispatch_time_desc";

  /**
   * 处置时间
   */
  static DISPOSE_TIME_DESC = "dispose_time_desc";

  /**
   * 处置截止时间
   */
  static CASE_STAGE_RED_TIME_DESC = "dispose_stage_deadline_desc";

  /**
   * 处置单位
   */
  static DISPOSE_DEPT_NAME = "dispose_dept_name";

  /**
   * 申请单位
   */
  static APPLY_DEPT_NAME = "apply_dept_name";

  /**
   * 申请人
   */
  static APPLY_USER_NAME = "apply_user_name";

  /**
   * 申请类型
   */
  static APPLY_TYPE_NAME = "apply_type_name";

  /**
   * 申请时间
   */
  static APPLY_TIME_DESC = "apply_time_desc";

  /**
   * 核查时间
   */
  static CHECK_TIME_DESC = "check_time_desc";

  /**
   * 核查人
   */
  static CHECK_USER_NAME = "check_user_name";

  /**
   * 作废环节
   */
  static CANCEL_EVENT_STATE_NAME = "cancel_event_state_name";

  /**
   * 挂账时间
   */
  static HANG_TIME_DESC = "hang_time_desc";

  constructor() {
    super();
  }

  initAddDatas() {
    this.add(CaseTableColumnEnum.TASK_NUM, "案件编号");
    this.add(CaseTableColumnEnum.CASE_STAGE_STATE, "环节状态");
    this.add(CaseTableColumnEnum.CASE_STAGE_DEADLINE, "截止时间倒计时");
    this.add(CaseTableColumnEnum.EVENT_DESC, "问题描述");
    this.add(CaseTableColumnEnum.EVENT_TYPE_NAME, "类型");
    this.add(CaseTableColumnEnum.MAIN_TYPE_NAME, "大类");
    this.add(CaseTableColumnEnum.SUB_TYPE_NAME, "小类");
    this.add(CaseTableColumnEnum.EVENT_STATE_NAME, "案件状态");
    this.add(CaseTableColumnEnum.CREATE_TIME_DESC, "上报时间");
    this.add(CaseTableColumnEnum.EVENT_SRC_TYPE_NAME, "案件来源");
    this.add(CaseTableColumnEnum.FULL_DISTRICT_NAME, "所属区域");
    this.add(CaseTableColumnEnum.ADDRESS, "案件位置");
    this.add(CaseTableColumnEnum.VERIFY_USER_NAME, "核实人");
    this.add(CaseTableColumnEnum.VERIFY_TIME_DESC, "核实时间");
    this.add(CaseTableColumnEnum.DISPATCH_TIME_DESC, "派遣时间");
    this.add(CaseTableColumnEnum.DISPOSE_TIME_DESC, "处置时间");
    this.add(CaseTableColumnEnum.CASE_STAGE_RED_TIME_DESC, "处置截止时间");
    this.add(CaseTableColumnEnum.DISPOSE_DEPT_NAME, "处置单位");
    this.add(CaseTableColumnEnum.APPLY_DEPT_NAME, "申请单位");
    this.add(CaseTableColumnEnum.APPLY_USER_NAME, "申请人");
    this.add(CaseTableColumnEnum.APPLY_TYPE_NAME, "申请类型");
    this.add(CaseTableColumnEnum.APPLY_TIME_DESC, "申请时间");
    this.add(CaseTableColumnEnum.CHECK_TIME_DESC, "核查时间");
    this.add(CaseTableColumnEnum.CHECK_USER_NAME, "核查人");
    this.add(CaseTableColumnEnum.CANCEL_EVENT_STATE_NAME, "作废环节");
    this.add(CaseTableColumnEnum.HANG_TIME_DESC, "挂账时间");
  }
}

export class CrossCasePhaseEnum extends Enum {
  static ENUM_NAME = Symbol("CrossCasePhaseEnum");
  static ACCEPT = 2;
  static FILE = 4;
  static DISPATCH = 6;
  static DISPOSE = 8;
  static CHECK = 13;
  static CLOSE = 14;
  static ARCHIVE = 101;
  static INVALID = 102;
  static DELAY = 103;

  constructor() {
    super();
  }
  initAddDatas() {
    this.add(CrossCasePhaseEnum.ACCEPT, "受理阶段");
    this.add(CrossCasePhaseEnum.FILE, "立案阶段");
    this.add(CrossCasePhaseEnum.DISPATCH, "派遣阶段");
    this.add(CrossCasePhaseEnum.DISPOSE, "处置阶段");
    this.add(CrossCasePhaseEnum.CHECK, "核查阶段");
    this.add(CrossCasePhaseEnum.CLOSE, "结案阶段");
    this.add(CrossCasePhaseEnum.ARCHIVE, "存档阶段");
    this.add(CrossCasePhaseEnum.INVALID, "作废阶段");
    this.add(CrossCasePhaseEnum.DELAY, "缓办阶段");
  }
}

export class DistributeMethod extends Enum {
  static ENUM_NAME = Symbol("DistributeMethod");
  static SEQUENCE = 1;
  static FAIR = 2;
  constructor() {
    super();
  }
  initAddDatas() {
    this.add(DistributeMethod.SEQUENCE, "顺序");
    this.add(DistributeMethod.FAIR, "平均");
  }
}

// 答复授权
export class ReplyAuthResultEnum extends Enum {
  static ENUM_NAME = Symbol("ReplyAuthResultEnum");
  static PASS = 1;
  static FAIL = 2;
  constructor() {
    super();
  }
  initAddDatas() {
    this.add(ReplyAuthResultEnum.PASS, "授权");
    this.add(ReplyAuthResultEnum.FAIL, "不授权");
  }
}

// 指派对象
export class DispatchTypeEnum extends Enum {
  static ENUM_NAME = Symbol("DispatchTypeEnum");
  static TO_PRO = 2;
  static TO_SUB = 3;
  static TO_SELFSUB = 4;
  static TO_LOOP_SYS = 6;
  static TO_INSPECTIONS = 7;

  static TO_COMMAND_CENTER = 10;
  static TO_CHILD_DEPARTMENT = 11;
  static DISTRICT_COMMAND_CENTER = 12;
  static DISTRICT_PROFESSIONAL_DEPT = 13;
  static PROFESSIONAL_DEPT = 14;
  static COMMAND_CENTER = 15;
  static EXTERNAL_SYSTEM = 20;
  constructor() {
    super();
  }
  initAddDatas() {
    // this.add(DispatchTypeEnum.TO_COMMAND_CENTER, "市监督指挥中心");
    // this.add(DispatchTypeEnum.DISTRICT_COMMAND_CENTER, "区级监督指挥中心");
    // this.add(DispatchTypeEnum.COMMAND_CENTER, "监督指挥中心");

    this.add(DispatchTypeEnum.TO_PRO, "上报至上级单位");
    this.add(DispatchTypeEnum.TO_SUB, "下级单位");
    this.add(DispatchTypeEnum.TO_SELFSUB, "本部门及下级部门");
    // this.add(DispatchTypeEnum.DISTRICT_PROFESSIONAL_DEPT, "区级专业部门");
    // this.add(DispatchTypeEnum.PROFESSIONAL_DEPT, "专业部门");

    // this.add(DispatchTypeEnum.TO_CHILD_DEPARTMENT, "下级部门");
    // this.add(DispatchTypeEnum.TO_INSPECTIONS, "监督巡查");
    this.add(DispatchTypeEnum.TO_LOOP_SYS, "行业应用(内循环)");
    // this.add(DispatchTypeEnum.EXTERNAL_SYSTEM, "第三方系统");


    // this.add(DispatchTypeEnum.TO_INSPECTIONS, "监督巡查");
    // this.add(DispatchTypeEnum.TO_LOOP_SYS, "行业单位");
  }
}


export class CaseDispatchConfigEnum extends Enum {

      static RECOMMAND = 1; // 推荐派遣
      static ONKEY = 2; // 一键派遣
      static AUTO = 3; // 自动派遣
      static PARALLEL = 4; // 并行派遣
      static CROSS_REGION = 5; // 跨区派遣
      static MAJOR = 6; // 主协办派遣
      static DOUBLE = 7; // 双派遣
      static FORCE = 8; // 强制派遣

      static TIME = 9; // 定时派遣
      static REWORK = 10; // 返工派遣

}

export class CheckDisapprovalEnum extends Enum{
  static pro_dept_handle = 1;
  static user_handle = 2;
  static industry_handle = 3;
  static external_handle = 4;
  initAddDatas() {
    this.add(CheckDisapprovalEnum.pro_dept_handle, "专业部门重新处置");
    this.add(CheckDisapprovalEnum.user_handle, "监督员重新核查");
    this.add(CheckDisapprovalEnum.industry_handle, "行业单位重新处置");
    this.add(CheckDisapprovalEnum.external_handle, "第三方系统重新处置");

  }
}

export class DisposeDeptWayEnum extends Enum{
  static only_first_dept = 1;
  static cur_dept = 2;
  initAddDatas() {
    this.add(DisposeDeptWayEnum.only_first_dept, "需专业一级部门完成处置");
    this.add(DisposeDeptWayEnum.cur_dept, "仅当前处置部门完成处置");

  }
}
export class DisposeAssignEnum extends Enum{
  static a = 1;
  static b = 2;
  initAddDatas() {
    this.add(DisposeAssignEnum.a, "执法队员处理");
    this.add(DisposeAssignEnum.b, "商家整改处理");

  }
}
