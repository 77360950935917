
import {DetailParams} from "../../abase/DetailParams";
import InfoItem from "../base/info-item";
import InfoBlockArea from "../base/info-block-area";

import InfoGroup from "../base/info-group";
import InfoNoData from "../base/info-no-data";

export default {
  name: "info-hang",
  components: {InfoNoData, InfoGroup,  InfoBlockArea, InfoItem,},
  props: {
    detail_info: {
      type: Object,
      default() {
        return {};
      }
    },
    detail_params: DetailParams,
  },
  computed: {},
  methods: {

  }
}
