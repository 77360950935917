
import LoginUtil from "vuecc/framework/libs/LoginUtil";
import {is_mobile} from "../a-hybrid/utils";
import {getBaseUrl} from "@/http/config";


export default {
  name: "case-image-preview",
  components: { },
  props:{
    pics:Array,
    layout:Object,
    show_num:{
      type:Number,
      default:2,
    },
    height:{
      type:Number,
      default:220,
    },
    theme:{
      type:String,
      default:'mini',
    },//mini,mini_slide,big_slide
  },
  watch:{
    pics:{
      immediate:true,
      handler(){
        if(this.pics && this.pics.length){
          this.pics_loc = this.pics.map(pic =>{
            return {
              ...pic,
            }
          })
          this.showItem = this.pics_loc[0];

          this.pics_loc.forEach(picture => {
            if (picture.pic_draw_info) {
              this.composePicture(picture)
            }
          });
        }
      }
    }
  },
  computed:{
    info_mark(){
      return this.layout?.name.substring(0,2);
    }
  },
  data(){
    return {
      pics_loc:[],
      toMp3Url:`${getBaseUrl()}/upfile_server/core/upfile/mp3?ses_token=${LoginUtil.getToken()}`,
      showItem:{},
      showIndex:0,
      is_mobile:is_mobile(),
    }
  },
  methods:{
    composePicture(picture) {
      return new Promise(resolve => {
        const { pic_draw_info, url } = picture;
        console.log('bbbbbbbbbbbbbbb');
        const aiCanvas = document.createElement("canvas");
        aiCanvas.width = pic_draw_info.width;
        aiCanvas.height = pic_draw_info.height;
        const ctx = aiCanvas.getContext("2d");
        const img = new Image();
        img.onload = function() {
          try {
            ctx.drawImage(img, 0, 0);
            ctx.lineWidth = 2;//设置线条宽度
            ctx.strokeStyle = "red";
            for (let rect of pic_draw_info.areas) {
              const { xmin, xmax, ymin, ymax } = rect;
              ctx.strokeRect(xmin, ymin, xmax - xmin, ymax - ymin);
            }
            let dataUrl = aiCanvas.toDataURL();
            picture.url = dataUrl;
          } catch (e) {
            console.log(e);
          } finally {
            resolve(picture.url);
          }
        };
        img.onerror = function() {
          resolve(picture.url);
        };
        img.src = url;
      });
    },
    preview(index){
      this.$refs.modal.open(index);
    },
    showBig(index){
      this.showIndex = index;
      this.showItem = this.pics_loc[index];
    }
  }
}
