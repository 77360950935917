import apiZhjg from "./apiZhjg";

/********************************案件流程设置***********************************-*/


/*** 流程设置列表**/

export const caseProcedureSetupList = params =>
    apiZhjg.http.post("/event_server/procedureSetup/list", params);

/*** 添加程序列表 **/

export const caseProcedureSetupAdd = params =>
    apiZhjg.http.post("/event_server/procedureSetup/add", params);

export const caseProcedureSetupEdit = params =>
    apiZhjg.http.post("/event_server/procedureSetup/edit", params);

export const caseProcedureSetupEnable = params =>
    apiZhjg.http.post("/event_server/procedureSetup/enable", params);

export const caseProcedureSetupDisable = params =>
    apiZhjg.http.post("/event_server/procedureSetup/disable", params);

export const caseProcedureSetupPublish = params =>
    apiZhjg.http.post("/event_server/procedureSetup/publish", params);

export const caseProcedureSetupDelete = params =>
    apiZhjg.http.post("/event_server/procedureSetup/delete", params);


export const caseProcedureSetupCopyOtherList = params =>
    apiZhjg.http.post("/event_server/procedureSetup/copyOtherList", params);

// 获取默认程序
export const caseProcedureSetupGet = params =>
    apiZhjg.http.post("/event_server/procedureSetup/get", params);


/*** 保存流程画布**/
export const caseFlowconfigSaveCanvas = params =>
    apiZhjg.http.post("/event_server/flowconfig/saveCanvas", params);

/*** 清空画布**/
export const caseFlowconfigClear = params =>
    apiZhjg.http.post("/event_server/flowconfig/clear", params);

/*** 导入流程**/
export const caseFlowconfigImportDefConf = params =>
    apiZhjg.http.post("/event_server/flowconfig/importDefConf", params);

/*** 导入导出外部模板**/
export const caseFlowconfigImportOtherConf = "/event_server/config-tpl/import";
export const caseFlowconfigExportOtherConf = "/event_server/config-tpl/export"

/*** 获取流程画布**/

export const caseFlowconfigCanvas = params =>
    apiZhjg.http.post("/event_server/flowconfig/canvas", params);

/*** 获取流程属性**/

export const caseFlowconfigProperty = params =>
    apiZhjg.http.post("/event_server/flowconfig/property", params);

/*** 保存流程属性**/

export const caseFlowconfigSaveProperty = params =>
    apiZhjg.http.post("/event_server/flowconfig/saveProperty", params);

/*** 流程-所有操作**/

export const caseFlowconfigActions = params =>
    apiZhjg.http.post("/event_server/flowconfig/actions", params);


/*** 获取节点属性**/

export const caseFlowconfigNodeProperty = params =>
    apiZhjg.http.post("/event_server/flowconfig/nodeProperty", params);



/*** 获取节点属性**/

export const caseFlowconfigSaveCopyNode = params =>
    apiZhjg.http.post("/event_server/flowconfig/copyNode", params);

/*** 保存节点属性**/

export const caseFlowconfigSaveNodeProperty = params =>
    apiZhjg.http.post("/event_server/flowconfig/saveNodeProperty", params);


/*** 获取线之前的节点 **/

export const caseFlowconfigLinkNodes = params =>
    apiZhjg.http.post("/event_server/flowconfig/linkNodes", params);


/*** 线-之前的操作 **/

export const caseFlowconfigLinkActions = params =>
    apiZhjg.http.post("/event_server/flowconfig/linkActions", params);


/*** 获取线上的字段 **/
export const caseFlowconfigLinkSchemas = params =>
    apiZhjg.http.post("/event_server/flowconfig/linkSchemas", params);


/*** 获取线上的字段 **/

export const caseFlowconfigLinkSchema = params =>
    apiZhjg.http.post("/event_server/flowconfig/linkSchema", params);


/*** 获取线属性 **/

export const caseFlowconfigLinkProperty = params =>
    apiZhjg.http.post("/event_server/flowconfig/linkProperty", params);



/*** 保存线属性 **/

export const caseFlowconfigSaveLinkProperty = params =>
    apiZhjg.http.post("/event_server/flowconfig/saveLinkProperty", params);


/*** 流程编辑心跳(编辑时需每10秒请求一次) **/

export const caseFlowconfigEditKeepalive = params =>
    apiZhjg.http.post("/event_server/flowconfig/editKeepalive", params);




export const caseFlowconfigSaveMark = params =>
    apiZhjg.http.post("/event_server/flowconfig/saveMark", params);



export const caseFlowconfigMarkList = params =>
    apiZhjg.http.post("/event_server/flowconfig/markList", params);




export const caseFlowconfigActionList = params =>
    apiZhjg.http.post("/event_server/flowconfig/actionList", params);



export const caseFlowconfigSaveNodePropertyAction = params =>
    apiZhjg.http.post("/event_server/flowconfig/saveNodePropertyAction", params);



/********************************案件流程设置***********************************-*/





/********************************字段配置管理***********************************-*/


export const customSchemaConfig = {
    list:params =>  apiZhjg.http.post("/event_server/custom-schema/list", params),
    save:params =>  apiZhjg.http.post("/event_server/custom-schema/save", params),
    del:params =>  apiZhjg.http.post("/event_server/custom-schema/del", params),
};


/********************************列表配置管理***********************************-*/

/********************************列表配置管理***********************************-*/


export const processConfServerListConfig = {
    list:params =>  apiZhjg.http.post("/event_server/listConfig/list", params),
    searchSave:params =>  apiZhjg.http.post("/event_server/listConfig/searchSave", params),
    searchList:params =>  apiZhjg.http.post("/event_server/listConfig/searchList", params),
    delete:params =>  apiZhjg.http.post("/event_server/listConfig/delete", params),
    info:params =>  apiZhjg.http.post("/event_server/listConfig/info", params),
    addOrUpdate:params =>  apiZhjg.http.post("/event_server/listConfig/addOrUpdate", params),
    layout:params =>  apiZhjg.http.post("/event_server/listConfig/layout", params),
    saveLayout:params =>  apiZhjg.http.post("/event_server/listConfig/saveLayout", params),
};


/********************************列表配置管理***********************************-*/


/********************************惯用语***********************************-*/




export const processConfServerLanguage = {
    list:params =>  apiZhjg.http.post("/event_server/language/list", params),
    save:params =>  apiZhjg.http.post("/event_server/language/save", params),
    del:params =>  apiZhjg.http.post("/event_server/language/del", params),
};






export const processConfServerProcedureQuery = {
    actionList:params =>  apiZhjg.http.post("/event_server/procedureQuery/actionList", params),
    nodeList:params =>  apiZhjg.http.post("/event_server/procedureQuery/nodeList", params),
    stageList:params =>  apiZhjg.http.post("/event_server/procedureQuery/stageList", params),
    actionListByNode:params =>  apiZhjg.http.post("/event_server/procedureQuery/actionListByNode", params),
    stageSave:params =>  apiZhjg.http.post("/event_server/procedureQuery/stageSave", params),

};




export const processConfServerEvaluateAlgorithm = {
    list:params =>  apiZhjg.http.post("/event_server/evaluate-algorithm/list", params),
    auth:params =>  apiZhjg.http.post("/event_server/evaluate-algorithm/auth", params),
    save:params =>  apiZhjg.http.post("/event_server/evaluate-algorithm/save", params),
    delete:params =>  apiZhjg.http.post("/event_server/evaluate-algorithm/delete", params),
};



export const processConfServerEvaluate = {
    list:params =>  apiZhjg.http.post("/event_server/statistic-report-setup/list", params),
    edit:params =>  apiZhjg.http.post("/event_server/statistic-report-setup/save", params),
    objectList:params =>  apiZhjg.http.post("/event_server/evaluate/objectList", params),
};

export const processConfServerQuotaPage = {
    list:params =>  apiZhjg.http.post("/event_server/quota-page/list", params),
    save:params =>  apiZhjg.http.post("/event_server/quota-page/save", params),
    quota_relation_list:params =>  apiZhjg.http.post("/event_server/quota-page/quota-relation-list", params),
};



export const processConfServerEvaluateReport = {
    list:params =>  apiZhjg.http.post("/event_server/statistic-report/list", params),
    turnDownList:params =>  apiZhjg.http.post("/event_server/statistic-report/turnDownList", params),
};

export const processConfServerDispatchConfig = {
    typeList:params =>  apiZhjg.http.post("/event_server/dispatch-config/typeList", params),
    typeSave:params =>  apiZhjg.http.post("/event_server/dispatch-config/typeSave", params),
    autoRuleList:params =>  apiZhjg.http.post("/event_server/dispatch-config/autoRuleList", params),
    autoRuleSave:params =>  apiZhjg.http.post("/event_server/dispatch-config/autoRuleSave", params),
    autoRuleDel:params =>  apiZhjg.http.post("/event_server/dispatch-config/autoRuleDel", params),
    autoRuleChooseSubTypeIdList:params =>  apiZhjg.http.post("/event_server/dispatch-config/autoRuleChooseSubTypeIdList", params),
    parallelRuleList:params =>  apiZhjg.http.post("/event_server/dispatch-config/parallelRuleList", params),
    parallelRuleSave:params =>  apiZhjg.http.post("/event_server/dispatch-config/parallelRuleSave", params),
    parallelRuleDel:params =>  apiZhjg.http.post("/event_server/dispatch-config/parallelRuleDel", params),
    parallelRuleDetail:params =>  apiZhjg.http.post("/event_server/dispatch-config/parallelRuleDetail", params),
    parallelRuleChooseSubTypeIdList:params =>  apiZhjg.http.post("/event_server/dispatch-config/parallelRuleChooseSubTypeIdList", params),
};

export const event_server_handle_conf = {
    supervise:{
        list:params =>  apiZhjg.http.post("/event_server/supervise-level/list", params),
        save:params =>  apiZhjg.http.post("/event_server/supervise-level/save", params),
        del:params =>  apiZhjg.http.post("/event_server/supervise-level/del", params),
    }
}


export const processConfServerDistributionConfig = {
    userList:params =>  apiZhjg.http.post("/event_server/distribution-config/userList", params),
    userSave:params =>  apiZhjg.http.post("/event_server/distribution-config/userSave", params),
    prioritySwitchSave:params =>  apiZhjg.http.post("/event_server/distribution-config/prioritySwitchSave", params),
    prioritySwitchList:params =>  apiZhjg.http.post("/event_server/distribution-config/prioritySwitchList", params),
    priorityTypeSave:params =>  apiZhjg.http.post("/event_server/distribution-config/priorityTypeSave", params),
    priorityTypeList:params =>  apiZhjg.http.post("/event_server/distribution-config/priorityTypeList", params),
};

export const stagetimeConfig = {
    save: params =>  apiZhjg.http.post("/event_server/stagetime-config/save", params),
    list: params =>  apiZhjg.http.post("/event_server/stagetime-config/list", params),
}


export const statisticCustomApi = {
    apiList:params =>  apiZhjg.http.post("/event_server/statistic-custom/apiList", params),
    typeList:params =>  apiZhjg.http.post("/event_server/statistic-custom/typeList", params),
    dimensionList:params =>  apiZhjg.http.post("/event_server/statistic-custom/dimensionList", params),
    testApi:params =>  apiZhjg.http.post("/event_server/statistic-custom/testApi", params),
    delApi:params =>  apiZhjg.http.post("/event_server/statistic-custom/delApi", params),
    saveApi:params =>  apiZhjg.http.post("/event_server/statistic-custom/saveApi", params),
    quotaTypeList:params =>  apiZhjg.http.post("/event_server/statistic-custom/quotaTypeList", params),
};

