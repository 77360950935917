
import Detail from '../detail/index.vue'
export default {
	name: "detail-form",
	components: {
        Detail,
	},
	props: {
        listId: 0,
        is_query_list: Boolean,
        query_list_params:Object,
        query_list_name:'',
        query_list_count:0,
        turnDownList:Function,
	},

	data() {
		return {
            listCode:this.is_query_list?'event':'',
			form_data: {
                currentInfo: {},
            },
			form_title: '详情',
		}
	},
	computed: {

	},
	methods: {
		open(form_data, resolve) {
			this.form_data.currentInfo = {
                ...form_data
			};
            this.form_title = form_data.taskNum
			this.$refs.form.open(({ form_data, ok }) => {
				console.log(form_data);
				// setTimeout(() => {
				// 	ok();
				// 	resolve();
				// }, 1000);
			})

		},


	}
}
