
import {is_mobile} from "../../a-hybrid/utils";
import {DetailParams} from "../abase/DetailParams";
import HybridButton from "../../a-hybrid/lib/hybrid-button";
import {caseHandle} from "@api";
import LoginUtil from "vuecc/framework/libs/LoginUtil";
import {getBaseUrl} from "@/http/config";

import {AttachmentExt} from "xb-lib-view/dist/utils";

export default {
  name: "stage-info-action",
  components: {HybridButton},
  props:{
    detail_info:{
      type:Object,
      default(){
        return {};
      }
    },
    detail_params: DetailParams,
  },
  data(){
    return {
      is_mobile:is_mobile(),
      selectedRowKeys:[],
      columns:[
        {
          title: "缩略图",
          dataIndex: "pic",
          scopedSlots: { customRender: "pic" }
        },
        {
          title: "文件类型",
          dataIndex: "type"
        },
        {
          title: "所属操作",
          dataIndex: "actionName"
        },
        {
          title: "上传时间",
          dataIndex: "createTime"
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" }
        },


      ],
      toMp3Url:`${getBaseUrl()}/upfile_server/core/upfile/mp3?ses_token=${LoginUtil.getToken()}`,
    }
  },
  computed:{
    dataSource(){
      let infos = this.detail_info.attachmentInfos || [];
      infos.forEach((item,index) =>{
        item.id = index;
      })
      return infos;
    },
    pics(){
      let pics =[];
      this.dataSource.forEach(item =>{
        let type = AttachmentExt.getType(item.pic?.url);
        let typeName = '附件';
        if(type == AttachmentExt.image){
          typeName = '图片';
        }
        if(type == AttachmentExt.video){
          typeName = '视频';
        }
        if(type == AttachmentExt.audio){
          typeName = '音频';
        }
        item.type = typeName;
        pics.push(item.pic);
      });
      return pics;
    }
  },
  methods:{
    open(){
      this.$refs.modal.open();
    },
    onSelectChange(v){
      this.selectedRowKeys = v;
    },
    preview(index){
      this.$refs.modal.open(index);
    },
    downAll(){
      let rows = [];
      this.dataSource.forEach(item =>{
        if(this.selectedRowKeys.indexOf(item.id) >= 0){
          item.url = item.pic.url,
          rows.push(item);
        }
      })
      caseHandle.banli.batchDownload({
        id:this.detail_info.id,
        attachments:rows,
      }).then(res =>{
        if(res.ok){
          const eleLink = document.createElement('a');
          eleLink.download = '';
          eleLink.style.display = 'none';
          eleLink.href = res.data;

          // 触发点击
          document.body.appendChild(eleLink);
          eleLink.click();
          // 然后移除
          document.body.removeChild(eleLink);
        }
      })
    }
  }
}
